"use client";
import type { InfiniteData } from "@tanstack/react-query";

import { getProductsFilterParameters } from "@/lib/hooks/plpUseProductsFilter/getProductFilterParameters";

import { getProducts } from "@/lib/hooks/useProductFilter/getProducts";
import {
  ClearFilters,
  IFilterData,
  ITags,
  SortOrder,
  useProductFilter,
} from "@/lib/hooks/useProductFilter/useProductFilter";
import formatProductsForPlp from "../clientUtils/formatProductsForPlp";
import sortSizesByValue from "../clientUtils/sortSizesByValue";
import { type InitialPlpData } from "../serverUtils/getInitialPlpData";

export function usePlp(
  initialProductData: InitialPlpData["products"],
  categories: string[],
  initialFilterData: InitialPlpData["initialFilterData"],
): UsePLPReturn {
  const initialData: InfiniteData<Awaited<ReturnType<typeof getProducts>>> = {
    pages: [initialProductData],
    pageParams: [],
  };
  const filterParameters = getProductsFilterParameters(categories);
  const initialState = {
    cardFormatter: filterParameters.cardFormatter,
    category: filterParameters.category as string[],
    initialData,
    productIds: filterParameters.productIds,
    count: filterParameters.productsPerPage ?? 8,
    initialFilterData,
  };
  const data = useProductFilter(initialState);
  const availableFilters = data.filterObjects?.map((group) => {
    return {
      ...group,
      availableFilters: group.filters.filter((f) => f.available === true),
    };
  });
  let hero = !(data.tags.length || categories.length > 1);
  if (!data.hero?.products) {
    hero = false;
  }
  return {
    clearFilters: data.clearFilters,
    ModalsProps: {
      filterModalProps: {
        filterObjects: sortSizesByValue(availableFilters),
        tags: data.tags,
        clearFilters: data.clearFilters,
      },
      SortOrderProps: data.sortOrder,
    },
    ProductsData: {
      plpContent: hero
        ? formatProductsForPlp(data.hero.products)
        : formatProductsForPlp(data.products),
      fetchNextPage: data.fetchNextPage,
      hasNextPage: hero ? data.hero.hasNextPage : data.hasNextPage,
      isFetching: data.isFetching,
      productCount: hero ? data.hero.productCount : data.productCount,
    },
  };
}

export type UsePLPReturn = {
  clearFilters: () => void;
  ModalsProps: {
    filterModalProps: Omit<FilterModalProps, "categories" | "params">;
  } & { SortOrderProps: SortOrder };
  ProductsData: PlpProductsSectionProps;
};
export type PlpModalProps = {
  filters: FilterModalProps;
  productCount?: number;
};

export type FilterModalProps = {
  filterObjects: IFilterData;
  tags: ITags;
  clearFilters: ClearFilters;
  categories: InitialPlpData["categories"];
  params?: { category: string[]; locale: string };
};

export type PlpProductsSectionProps = {
  plpContent: PLPContent;
  fetchNextPage: FetchNextPageType;
  hasNextPage: boolean | undefined;
  isFetching: boolean;
  productCount?: number;
};

export type PLPContent = ReturnType<typeof formatProductsForPlp>;
export type FetchNextPageType = ReturnType<
  typeof useProductFilter
>["fetchNextPage"];
