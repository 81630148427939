// import { ParameterType } from "@/lib/hooks/plpUseProductsFilter/getProductFilterParameters";
import type { InfiniteData } from "@tanstack/react-query";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import flatten from "lodash.flatten";

import useLocation from "@/lib/hooks/useLocation";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import { InitialPlpData } from "@/lib/plp/serverUtils/getInitialPlpData";
import { IFilterGroup } from "@/types/centra";
import { useRef } from "react";
import type { CentraProduct } from "../../centra/atomicSetup";
import type { ProductCard } from "../../centra/formatters/formatProductCards/formatProductCard";
import type { IFilterObject } from "./filterFormatter";
import filterFormatter from "./filterFormatter";
import useFilters from "./filterHooks/useFilters";
import { useSearch } from "./filterHooks/useSearch";
import { useSortOrder } from "./filterHooks/useSortOrder";
import { getProducts } from "./getProducts";
import productReducer from "./productReducer";

export type RawFilterResponse = {
  token: string;
  products: CentraProduct[];
  productCount: number;
  filter: any[];
};

export type FilterResponse<PC extends ProductCard> = {
  token: string;
  products: PC[];
  productCount: number;
  filter: any[];
};

export type FilterParameters<> = {
  initialData: InitialData;
};
export type InitialData<> = InfiniteData<
  Awaited<ReturnType<typeof getProducts>>
>;

export const useProductFilter = <PC extends ProductCard, ExtraData = unknown>({
  cardFormatter,
  category = [],
  initialData,
  productIds = undefined,
  count = 8,
  extraDataFetcher,
  initialFilterData,
}: {
  cardFormatter: (product: CentraProduct, data?: ExtraData) => PC;
  category: string[];
  filterGroups?: IFilterGroup[];
  initialData?: InfiniteData<
    Awaited<ReturnType<typeof getProducts<PC, ExtraData>>>
  >;
  productIds: string[] | undefined;
  count: number;
  extraDataFetcher?: (productIds: string[]) => Promise<ExtraData>;
  initialFilterData: InitialPlpData["initialFilterData"];
}) => {
  // const { addWishlistToProductCards } = useWishlist();
  const customerToken = usePersistedStore((state) => state.token);
  const { filters } = useFilters(initialFilterData);

  const { location } = useLocation();

  const activeFilters = filters?.reduce(
    (count: any, filter: any): number =>
      filter.values ? (count += filter.values.length) : count,
    0,
  )
    ? filters.map((filter: any) => ({
        name: filter.name,
        values: filter.values,
      }))
    : undefined;

  // Setting up search
  const search = useSearch();

  // Setting up sort order
  const sortHook = useSortOrder();
  const { active: sortOrder } = sortHook;

  // Building function to clear all filters
  const clearFilters = () => {
    filters?.forEach((filter: any) => filter.clear());
  };

  /**
   * Keeping track of when the data was last updated to keep previous data
   */
  const updatedAt = useRef<number>();

  // Building infiniteQuery
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [
      "products",
      {
        category,
        productIds,
        activeFilters,
        sortOrder: sortOrder,
        search: search.value,
        location,
      },
    ],
    queryFn: async ({ pageParam, signal }) => {
      const data = await getProducts({
        category,
        cardFormatter,
        filters: filters,
        productIds,
        sortOrder: sortOrder.value,
        search: search.value,
        page: pageParam as number,
        productsPerPage: count,
        extraDataFetcher,
        customerToken,
        signal,
      });

      updatedAt.current = Date.now();

      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPageData, pages) =>
      lastPageData.productCount > 8 * pages.length ? pages.length : undefined,
    refetchOnWindowFocus: true,
    /**
     * We must invalidate the initial data once we have new data in order to use `placeholderData: keepPreviousData`
     * @source https://tanstack.com/query/latest/docs/framework/react/guides/initial-query-data#initial-data-from-the-cache-with-initialdataupdatedat
     */
    initialData: updatedAt.current ? undefined : initialData,
    placeholderData: keepPreviousData,
    initialDataUpdatedAt: () => updatedAt.current,
  });

  // Reducing products to single array and adding wishlist status
  // const productsWithoutWishlist = data
  //   ? productReducer<IProductCard>(data)
  //   : undefined;

  // const products = productsWithoutWishlist
  //   ? addWishlistToProductCards(productsWithoutWishlist)
  //   : undefined;

  const products = data ? productReducer(data) : undefined;
  const filter = data ? data.pages[0].filter : undefined;

  const productCount = data?.pages[0] ? data.pages[0].productCount : undefined;

  const hero = data?.pages[0] ? data.pages[0].hero : undefined;

  const filterObjects: IFilterData = data?.pages[0]
    ? filterFormatter(data?.pages[0].filter, filters)
    : undefined;

  const tags: ITags = flatten(
    filterObjects?.map((group) =>
      group.filters.map((filter) =>
        filter.active
          ? {
              value: filter.value,
              color:
                // filter?.colorCode ??
                "D1CFC9",
              toggle: filter.toggle,
            }
          : undefined,
      ),
    ),
  ).filter((i) => {
    return i !== undefined;
  }) as ITags;

  return {
    products,
    productCount,
    hero,
    filter,
    filterObjects,
    search,
    tags,
    sortOrder: sortHook,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    clearFilters,
  };
};

export type IFilterData =
  | {
      name: string;
      filters: IFilterObject[];
      isOpen: () => boolean;
      toggleOpen: () => void;
    }[]
  | undefined;

export type ITags = {
  value: string;
  color: string;
  toggle: () => void;
}[];

export type UseProductFilterReturn = ReturnType<typeof useProductFilter>;
export type SortOrder = UseProductFilterReturn["sortOrder"];
export type ClearFilters = UseProductFilterReturn["clearFilters"];
